import { render, staticRenderFns } from "./addLearnerToCourse.vue?vue&type=template&id=3ea72c05&scoped=true"
import script from "./addLearnerToCourse.vue?vue&type=script&lang=js"
export * from "./addLearnerToCourse.vue?vue&type=script&lang=js"
import style0 from "./addLearnerToCourse.vue?vue&type=style&index=0&id=3ea72c05&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ea72c05",
  null
  
)

export default component.exports