var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"QaEmail"}},[_c('div',{},[(_vm.card_data.description)?_c('p',{staticClass:"learn-info",domProps:{"innerHTML":_vm._s(_vm.card_data.description)}}):_vm._e(),_c('div',{staticClass:"learn-object"},[_c('div',{staticClass:"learn-object-head"},[_c('div',{staticClass:"learn-object-col"},[_c('div',{staticClass:"learn-object-titles"},[_c('h5',[_vm._v(_vm._s(_vm.character.attributes.char_full_name))]),_c('p',[_vm._v(" "+_vm._s(_vm.character.attributes.world_role)+"("+_vm._s(_vm.character.attributes.org_name)+") ")])])])]),(_vm.submitquestions.length)?_c('div',{staticClass:"learn-object-preview"},_vm._l((_vm.submitquestions),function(que,k){return _c('div',{key:k,staticClass:"learn-object-info"},[(k === 0)?_c('h4',{domProps:{"innerHTML":_vm._s(
              _vm.replaceLearnerToken(
                _vm.card_data.card_detail.attributes.introduction
              )
            )}}):_vm._e(),(que.attributes.attempt === 1)?_c('h4',{domProps:{"innerHTML":_vm._s(_vm.replaceLearnerToken(que.attributes.question))}}):_vm._e(),_c('div',{staticClass:"dialogic-answer"},[_c('p',{domProps:{"innerHTML":_vm._s(que.attributes.answer)}})]),(que.attributes.character_response)?_c('h4',{domProps:{"innerHTML":_vm._s(_vm.replaceLearnerToken(que.attributes.character_response))}}):_vm._e(),(que.attributes.follow_up_question)?_c('h4',{domProps:{"innerHTML":_vm._s(_vm.replaceLearnerToken(que.attributes.follow_up_question))}}):_vm._e()])}),0):_vm._e(),_c('div',{staticClass:"learn-object-info"},[(_vm.isTheEndOfConversation())?_c('h4',[_vm._v(" "+_vm._s(_vm.card_data.card_detail.attributes.conclusion)+" ")]):_vm._e()])]),_c('div',{staticClass:"admin-feedback-content modal-score-content"},[_c('div',{attrs:{"settings":_vm.settings}},[_c('div',{},[_vm._m(0),(_vm.overall_assmnt_item && _vm.overall_assmnt_item.display_feedback)?_c('div',{staticClass:"modal-score-strip mb-4"},[_c('span',{staticClass:"modal-score-icon"},[(_vm.overall_assmnt_item.assessment_icon_url)?_c('img',{attrs:{"src":_vm.overall_assmnt_item.assessment_icon_url,"alt":_vm.overall_assmnt_item.assessment_label}}):_vm._e(),_vm._v(" "+_vm._s(_vm.overall_assmnt_item.assessment_label)+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.overall_assmnt_item.feedback)}})]):_vm._e(),(_vm.dialogic_debrief_evaluation)?_c('div',_vm._l((_vm.dialogic_debrief_evaluation),function(dialogicdata,key){return _c('div',{key:key,staticClass:"dialogic-container"},[_c('div',{staticClass:"dialogic-heading"},[_vm._v(" "+_vm._s(_vm.dialogic_debrief_evaluation[key][0].attributes && _vm.dialogic_debrief_evaluation[key][0].attributes .question_concept ? _vm.dialogic_debrief_evaluation[key][0].attributes .question_concept : "")+" ")]),_c('div',{staticClass:"dialogic-carousel",attrs:{"responsive":{
                  0: { items: 1 },
                  768: { items: 2 },
                  1024: { items: 3 },
                  1367: { items: 3 },
                },"id":_vm.dialogic_debrief_evaluation[key]}},_vm._l((_vm.dialogic_debrief_evaluation[key]),function(dialogic){return _c('div',{key:dialogic.id},[(dialogic.attributes.key_topic_missed)?_c('div',{staticClass:"feedback-items missed-items"},[_c('div',{staticClass:"feedback-content"},[_c('div',{staticClass:"feedback-heads"},[_c('div',{staticClass:"feedback-icon"}),_c('div',{staticClass:"feedback-infos"},[_c('div',{staticClass:"feedback-head"},[_vm._v("Missed")]),_c('h4',[_vm._v(_vm._s(dialogic.attributes.topic_label))])])]),_c('p',{domProps:{"innerHTML":_vm._s(dialogic.attributes.debrief_received)}})])]):_c('div',{staticClass:"feedback-items mastery-items"},[_c('div',{staticClass:"feedback-content"},[_c('div',{staticClass:"feedback-heads"},[_c('div',{staticClass:"feedback-icon"},[_c('img',{attrs:{"src":dialogic.attributes.assessment_icon_url,"alt":dialogic.attributes.assessment_label}})]),_c('div',{staticClass:"feedback-infos"},[_c('div',{staticClass:"feedback-head"},[_vm._v(" "+_vm._s(dialogic.attributes.assessment_label)+" ")]),_c('h4',[_vm._v(_vm._s(dialogic.attributes.topic_label))])])]),_c('p',{domProps:{"innerHTML":_vm._s(dialogic.attributes.debrief_received)}})])])])}),0)])}),0):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-title modal-flex-title"},[_c('h3',[_vm._v("Feedback")])])
}]

export { render, staticRenderFns }