<template>
  <modal
    name="createCourse"
    height="auto"
    :width="750"
    :scrollable="true"
    @before-open="beforeOpen"
  >
    <div class="edit-courses-dialog">
      <div class="modal-content">
        <div class="edit-courses-page">
          <div class="modal-header">
            <div class="modal-title" id="exampleModalLabel">
              <h5 v-if="customer_id">Edit Course</h5>
              <h5 v-else>Add New Course</h5>
            </div>
            <button type="button" class="close" @click="hide('createCourse')">
              <em class="icon-close"></em>
            </button>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="form">
              <form class="modal-form">
                <div class="form-group">
                  <label class="form-label">Course Name</label>
                  <ValidationProvider
                    name="Name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      id="courseName"
                      aria-describedby="emailHelp"
                      v-model="courseName"
                      :class="[isActive && errors[0] ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[
                        isActive ? { display: 'block' } : { display: 'none' },
                      ]"
                      v-if="errors[0]"
                      >{{ errors[0].replace("The ", "") }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label class="form-label" for="exampleInputEmail1"
                    >Search Customer</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search Customer..."
                    v-model="searchCharacters"
                    v-on:input="getCharacters"
                  />
                  <div v-if="charactersList.length">
                    <div
                      class="dropdown-menu"
                      :class="[charactersList.length !== 0 ? 'show' : '']"
                    >
                      <ul class="dropdown-list">
                        <li
                          class="dropdown-items"
                          v-for="characters in charactersList"
                          :key="characters.id"
                        >
                          <div class="dropdown-content">
                            <h4>{{ characters.attributes.name }}</h4>
                          </div>
                          <div
                            class="dropdown-action"
                            v-if="!characters.status"
                          >
                            <a
                              @click="addCharacters(characters)"
                              class="add-action"
                              >+</a
                            >
                          </div>
                          <div class="dropdown-action" v-if="characters.status">
                            <em class="icon-check"></em>
                            <span>Added</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="contact-list-section" v-if="addedCharacters.name">
                  <div class="list-container">
                    <div class="list-box">
                      <div class="left-section">
                        <div
                          class="image-box"
                          v-if="addCharacters.photo_url != null"
                        >
                          <img
                            alt="images"
                            class="module-img"
                            :src="addCharacters.photo_url"
                          />
                        </div>
                        <h3 class="contact-text">{{ addedCharacters.name }}</h3>
                      </div>
                      <div class="right-section">
                        <a class="remove-link" @click="removeCharcter()"
                          >Remove</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="courses-tab-section">
                  <ul class="tab-section">
                    <li
                      :class="tab === 'address' ? 'tab active' : 'tab'"
                      @click="setTab('address')"
                    >
                      <a
                        :class="tab === 'address' ? 'link link-active' : 'link'"
                        >Module(s)</a
                      >
                    </li>
                    <li
                      :class="tab === 'payment' ? 'tab active' : 'tab'"
                      @click="setTab('payment')"
                    >
                      <a
                        :class="tab === 'payment' ? 'link link-active' : 'link'"
                        >Payment</a
                      >
                    </li>
                    <li
                      :class="tab === 'lti' ? 'tab active' : 'tab'"
                      @click="setTab('lti')"
                    >
                      <a :class="tab === 'lti' ? 'link link-active' : 'link'"
                        >LTI</a
                      >
                    </li>
                    <li
                      :class="tab === 'terms' ? 'tab active' : 'tab'"
                      @click="setTab('terms')"
                    >
                      <a :class="tab === 'terms' ? 'link link-active' : 'link'"
                        >Terms</a
                      >
                    </li>
                    <li
                      :class="tab === 'instructor' ? 'tab active' : 'tab'"
                      @click="setTab('instructor')"
                    >
                      <a
                        :class="
                          tab === 'instructor' ? 'link link-active' : 'link'
                        "
                        >Instructor(s)</a
                      >
                    </li>
                    <li
                      :class="tab === 'plagiarism' ? 'tab active' : 'tab'"
                      @click="setTab('plagiarism')"
                    >
                      <a
                        :class="
                          tab === 'plagiarism' ? 'link link-active' : 'link'
                        "
                        >Plagiarism</a
                      >
                    </li>
                  </ul>
                  <div
                    class="tab-detail-show"
                    id="address"
                    v-if="tab === 'address'"
                  >
                    <div class="add-module-section">
                      <div class="module-input">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search Module..."
                            v-model="searchModule"
                            v-on:input="getModules"
                          />
                          <div v-if="modulesList.length">
                            <div
                              class="dropdown-menu"
                              :class="[modulesList.length !== 0 ? 'show' : '']"
                            >
                              <ul class="dropdown-list">
                                <li
                                  class="dropdown-items"
                                  v-for="module in modulesList"
                                  :key="module.id"
                                >
                                  <div class="dropdown-content">
                                    <h4>{{ module.attributes.name }}</h4>
                                  </div>
                                  <div
                                    class="dropdown-action"
                                    v-if="!module.status"
                                  >
                                    <a
                                      @click="addModules(module)"
                                      class="add-action"
                                      >+</a
                                    >
                                  </div>
                                  <div
                                    class="dropdown-action"
                                    v-if="module.status"
                                  >
                                    <em class="icon-check"></em>
                                    <span>Added</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="module-box-section"
                      v-if="addedModules.length > 0"
                    >
                      <draggable v-model="addedModules">
                        <div
                          class="module-box"
                          v-for="(module, index) in addedModules"
                          :key="module.module_id"
                        >
                          <div class="detail-left">
                            <div
                              class="image-box"
                              v-if="module.photoUrl != false"
                            >
                              <img
                                alt="images"
                                class="module-img"
                                :src="module.photoUrl"
                              />
                            </div>
                            <div class="detail-text">
                              <h3 class="title-module">
                                {{ module.moduleName }}
                              </h3>
                              <p class="pg-module">
                                {{ module.worldName }}
                              </p>
                            </div>
                          </div>
                          <div class="remove-link" @click="removeModule(index)">
                            <a class="remove-text">Remove</a>
                          </div>
                        </div>
                      </draggable>
                    </div>
                    <div class="checkbox-section">
                      <div class="form-checkboxes">
                        <label class="custom-checkbox">
                          Learner must complete modules in order
                          <input
                            type="checkbox"
                            v-model="sequential_mod_access"
                            />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="checkbox-section">
                      <div class="form-checkboxes">
                        <label class="custom-checkbox">
                          Learner is delayed 12 hours from accessing the next module. This option can only be selected if the learner must complete modules in order.
                          <input
                            type="checkbox"
                            v-model="sequential_24_hour_delay"
                            :disabled="!sequential_mod_access"
                            />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="checkbox-section">
                      <div class="form-checkboxes">
                        <label class="custom-checkbox">
                          Disable learner retries
                          <input
                            type="checkbox"
                            v-model="disableLearnerRetries"
                            />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="checkbox-section">
                      <div class="form-checkboxes">
                        <label class="custom-checkbox">
                          Enable certificate of completion
                          <input
                            type="checkbox"
                            v-model="enableCertificateOfCompletion"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <input 
                          v-if="enableCertificateOfCompletion"
                          type="text"
                          class="form-control"
                          v-model="customCertificateTitle"
                          placeholder="Type customer certificate title here" 
                          :disabled="!enableCertificateOfCompletion"
                        />
                      </div>
                    </div>
                    <div class="checkbox-section">
                      <div class="form-checkboxes">
                        <label class="custom-checkbox">
                          Enable reporting interface in instructor application
                          <input
                            type="checkbox"
                            v-model="enable_reporting"
                          />
                          <span class="checkmark"></span>
                        </label>
                        
                      </div>
                    </div>
                    <div v-if="enable_reporting" class="reporting-indent">
                    
                      <div class="checkbox-section" >
                        <div class="form-checkboxes">
                          <label class="custom-checkbox">
                            Enable grading report
                            <input
                              type="checkbox"
                              v-model="enable_grading_report"
                            />
                            <span class="checkmark"></span>
                          </label>
                          
                        </div>
                      </div>
                      <div class="checkbox-section" >
                        <div class="form-checkboxes">
                          <label class="custom-checkbox">
                            Enable completion report
                            <input
                              type="checkbox"
                              v-model="enable_completion_report"
                            />
                            <span class="checkmark"></span>
                          </label>
                          
                        </div>
                      </div>
                      <div class="checkbox-section" >
                        <div class="form-checkboxes">
                          <label class="custom-checkbox">
                            Enable assessment report
                            <input
                              type="checkbox"
                              v-model="enable_assessment_report"
                            />
                            <span class="checkmark"></span>
                          </label>
                          
                        </div>
                      </div>
                    </div>
                    <div class="checkbox-section">
                      <div class="form-checkboxes">
                        <label class="custom-checkbox">
                          Display section name on learner dashboard cards
                          <input
                            type="checkbox"
                            v-model="learner_display_section"
                            />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="checkbox-section">
                      <div class="form-checkboxes">
                        <label class="custom-checkbox">
                          Auto-deploy sections (Campus Support LTI integration)
                          <input
                            type="checkbox"
                            v-model="auto_create_sections"
                            />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="checkbox-section">
                      <div class="form-checkboxes">
                        <label class="custom-checkbox">
                          Enable LLM LO Retries
                          <input
                            type="checkbox"
                            v-model="enable_llm_retries"
                            />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="checkbox-section" v-if="enable_llm_retries">
                      <div class="form-checkboxes">
                        <label>
                          LLM LO Retries Limit
                        </label>
                      </div>
                      <input
                        type="number"
                        v-model="llm_retries_limit"
                      />
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn default" @click="hide">
                        Cancel
                      </button>
                      <button
                        type="button"
                        class="btn primary"
                        @click="setTab('payment')"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                <div class="courses-tab-section" v-if="tab === 'payment'">
                  <div class="tab-detail-show" id="payment">
                    <div class="checkbox-section">
                      <div class="form-checkboxes">
                        <label class="custom-checkbox">
                          No Charges
                          <input type="checkbox" v-model="noCharge" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group" v-if="!noCharge">
                      <label class="form-label">Payment Type</label>
                      <div class="quiz-dropdown customer-quiz-dropdown">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="entity"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {{ paymentType.name }}
                          <em class="icon-carrot-down"></em>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="entity">
                          <option
                            class="dropdown-item"
                            @click="selectPaymentType(type)"
                            v-for="type of paymentTypes"
                            :key="type.key"
                          >
                            {{ type.name }}
                          </option>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="!noCharge">
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label">Cost Per Learner</label>
                          <input
                            type="number"
                            class="form-control"
                            id="courseName"
                            aria-describedby="emailHelp"
                            v-model="costPerLearner"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label">Invoice Frequency</label>
                          <div class="quiz-dropdown customer-quiz-dropdown">
                            <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="entity"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {{ invoiceType.name }}
                              <em class="icon-carrot-down"></em>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="entity">
                              <option
                                class="dropdown-item"
                                @click="selectInvoiceFrequency(type)"
                                v-for="type of invoiceFrequency"
                                :key="type.id"
                              >
                                {{ type.name }}
                              </option>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="annual-support-text">
                      Annual support & maintenance fee section
                    </p>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label">Cost</label>
                          <input
                            type="number"
                            class="form-control"
                            id="cost"
                            aria-describedby="emailHelp"
                            v-model="cost"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label">Number of Learners</label>
                          <input
                            type="number"
                            class="form-control"
                            id="numberOfLearners"
                            aria-describedby="emailHelp"
                            v-model="numberOfLearners"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label">Start Date</label>
                          <datepicker
                            v-model="startDate"
                            name="startDate"
                          ></datepicker>
                          <div class="calendar-icon">
                            <img
                              alt="images"
                              class="calendar-img"
                              src="../../assets/images/svg/calendar-icon.svg"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label">End Date</label>
                          <datepicker
                            v-model="endDate"
                            name="endDate"
                          ></datepicker>
                          <div class="calendar-icon">
                            <img
                              alt="images"
                              class="calendar-img"
                              src="../../assets/images/svg/calendar-icon.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label">Reconciled</label>
                          <div class="quiz-dropdown customer-quiz-dropdown">
                            <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="entity"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {{ reconciled.name }}
                              <em class="icon-carrot-down"></em>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="entity">
                              <option
                                class="dropdown-item"
                                @click="selectReconciled(type)"
                                v-for="type of invoiceFrequency"
                                :key="type.id"
                              >
                                {{ type.name }}
                              </option>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label"
                            >Overage Cost (per learner)</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="averageCost"
                            aria-describedby="emailHelp"
                            v-model="averageCost"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label">Currency</label>
                          <div class="quiz-dropdown customer-quiz-dropdown">
                            <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="entity"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {{ currency.name }}
                              <em class="icon-carrot-down"></em>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="entity">
                              <option
                                class="dropdown-item"
                                @click="selectCurrencyType(type)"
                                v-for="type of currentType"
                                :key="type.name"
                              >
                                {{ type.name }}
                              </option>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col tax-rate-col">
                        <div class="checkbox-section">
                          <div class="form-checkboxes">
                            <label class="custom-checkbox">
                              Taxable
                              <input type="checkbox" v-model="taxStatus" />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div class="form-group" v-if="taxStatus">
                          <label class="form-label">Tax Rate</label>
                          <ValidationProvider
                            name="Name"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <input
                              type="number"
                              class="form-control"
                              id="courseName"
                              aria-describedby="emailHelp"
                              v-model="tax"
                              max="10"
                              :class="[isActive && errors[0] ? 'invalid' : '']"
                            />
                            <span
                              :class="[isActive ? 'invalid' : '']"
                              :style="[
                                isActive
                                  ? { display: 'block' }
                                  : { display: 'none' },
                              ]"
                              v-if="errors[0]"
                              >{{ errors[0].replace("The ", "") }}</span
                            >
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn default" @click="hide">
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn primary"
                      @click="setTab('lti')"
                    >
                      Next
                    </button>
                  </div>
                </div>
                <div class="courses-tab-section" v-if="tab === 'lti'">
                  <div class="tab-detail-show" id="lti">
                    <div class="checkbox-section">
                      <div class="form-checkboxes">
                        <label class="custom-checkbox">
                          LTI Integration
                          <input
                            type="checkbox"
                            v-model="ltiIntegrationStatus"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group" v-if="ltiIntegrationStatus">
                      <label class="form-label">LMS</label>
                      <div class="add-module-section">
                        <div class="quiz-dropdown customer-quiz-dropdown">
                          <button
                            class="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="entity"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <em class="icon-carrot-down"></em>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="entity">
                            <option
                              class="dropdown-item"
                              @click="addLtiModule(module)"
                              v-for="module in ltiList"
                              :key="module.id"
                            >
                              {{ module.attributes.name }}
                            </option>
                          </div>
                        </div>
                      </div>
                      <div
                        class="module-box-section"
                        v-if="ltiModule && ltiModule.attributes"
                      >
                        <div class="module-box">
                          <div class="detail-left">
                            <div class="detail-text">
                              <h3 class="title-module">
                                {{ ltiModule.attributes.name }}
                              </h3>
                            </div>
                          </div>
                          <div class="remove-link" @click="removeLti">
                            <a class="remove-text">Remove</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="multi-checkbox"
                      v-if="ltiModule && ltiModule.attributes"
                    >
                      <div class="checkbox-section">
                        <div class="form-checkboxes">
                          <label class="custom-checkbox">
                            SSO
                            <input type="checkbox" v-model="ssdStatus" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div class="checkbox-section">
                        <div class="form-checkboxes">
                          <label class="custom-checkbox">
                            Grading
                            <input type="checkbox" v-model="gradingStatus" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div class="checkbox-section" v-if="gradingStatus">
                        <div class="form-checkboxes">
                          <label class="custom-checkbox">
                            Single Weighted Grade?
                            <input type="checkbox" v-model="singleWeightedGrade" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="checkbox-section" v-if="gradingStatus && singleWeightedGrade && this.weightedGrades.length > 0">
                      <select v-model="selectedLearnMod" @change="saveCourse">
                        <option v-for="learnMod in this.weightedGrades" :key="learnMod.id" :value="learnMod">
                          {{ learnMod.name }}
                        </option>
                      </select>
                      <div v-if="selectedLearnMod">
                        <div class="interaction-container" v-if="selectedLearnMod.interactions && selectedLearnMod.interactions.length > 0">
                          <div class="interaction-item" v-for="interaction in selectedLearnMod.interactions" :key="interaction.id">
                            <label>
                              {{ interaction.attributes.interaction_name }}
                            </label>
                            <input type="number" v-model="interaction.attributes.weight"/>
                          </div>
                        </div>
                        <div v-else>
                          <p>No interactions available for the selected module.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn default" @click="hide">
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn primary"
                      @click="setTab('terms')"
                    >
                      Next
                    </button>
                  </div>
                </div>
                <div class="courses-tab-section" v-if="tab === 'terms'">
                  <div class="tab-detail-show" id="terms">
                    <div class="checkbox-section">
                      <div class="form-checkboxes">
                        <label class="custom-checkbox">
                          Use Default
                          <input type="checkbox" v-model="useDefault" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div>
                      <p class="custom-term-text">Custom Terms & Conditions</p>
                      <vue-editor
                        class="form-control"
                        :editorToolbar="fullToolbar"
                        v-model="terms"
                      ></vue-editor>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn default" @click="hide">
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn primary"
                      @click="setTab('instructor')"
                    >
                      Next
                    </button>
                  </div>
                </div>
                <div class="courses-tab-section" v-if="tab === 'instructor'">
                  <div class="tab-detail-show" id="instructor">
                    <div class="checkbox-section">
                      <div class="form-checkboxes">
                        <label class="custom-checkbox">
                          Instructor Required?
                          <input
                            type="checkbox"
                            v-model="instructorReqStatus"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group" v-if="instructorReqStatus">
                      <label class="form-label"
                        >Maximum Numbers of Instructors</label
                      >
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Numbers of Instructors"
                        aria-describedby="emailHelp"
                        v-model="totalInstructor"
                      />
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn default" @click="hide">
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn primary"
                      @click="createCourses"
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div class="courses-tab-section" v-if="tab === 'plagiarism'">
                  <div class="tab-detail-show" id="instructor">
                    <div class="plagiarism-container">
                      <div class="elm-view">
                        <button
                          v-for="elm in this.addedModules"
                          :key="elm.id"
                          @click.prevent="getElmLOs(elm.module_id)"
                          class="btn elm-btn"
                        >
                          {{ elm.moduleName }}
                        </button>
                      </div>

                      <div class="mb-3 enable-course-plagiarism">
                        <input 
                            type="checkbox" 
                            name="enable_course_plagiarism_detection"
                            v-model="enable_course_plagiarism_detection"
                          />
                        <label for="enable_course_plagiarism_detection">Enable plagiarism detection in this course</label>
                      </div>

                      <div class="plagiarism-los-container" v-if="enable_course_plagiarism_detection">

                        <div
                          
                          class="lo-view"
                          v-for="(lo, index) in plagiarismLOs"
                              :key="lo.id"
                          >
                          <div class="lo-view__selection">
                            <button 
                            
                              class="btn lo-view__btn"
                              @click="switchActiveLO(lo.id)"
                            >
                              {{lo.name}}
                            </button>
                          </div>
                          
                          <div class="lo-view__settings">
                            <div class="lo-settings__checkbox">
                              <input 
                                type="checkbox" 
                                v-model="plagiarismLOs[index].enable_plagiarism_detection"
                              />
                              <label>Enable plagiarism detection on this LO</label>
                            </div>
                            <div class="lo-settings__range">
                              <label>Less Strict</label>
                              <input 
                                type="range"
                                v-model="plagiarismLOs[index].plagiarism_strictness"
                                min="0" 
                                max="100"
                                :disabled="!plagiarismLOs[index].enable_plagiarism_detection"
                              />
                              <label>More Strict</label>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn default" @click="hide">
                      Close
                    </button>
                    <button
                      type="button"
                      class="btn primary"
                      @click="savePlagiarismSetting"
                    >
                      Save Plagiarism Settings
                    </button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import api from "../../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import Datepicker from "vuejs-datepicker";
import { VueEditor } from "vue2-editor";
import draggable from "vuedraggable";

export default {
  name: "createCourse",
  components: {
    ValidationProvider,
    ValidationObserver,
    Datepicker,
    VueEditor,
    draggable,
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: false,
      },
      image: null,
      name: "",
      searchCharacters: "",
      charactersList: [],
      addedCharacters: [],
      removeCharacters: [],
      characters: [],
      isActive: false,
      url: utilFunctionService.getImageUrl(),
      customer_id: "",
      postalCode: "",
      province: "",
      country: "",
      customerType: "",
      courseName: "",
      customerTypes: [],
      tab: "address",
      contactList: [],
      searchModule: "",
      modulesList: [],
      addedModules: [],
      noCharge: false,
      paymentTypes: [
        { name: "Per Learner - Customer Pays", key: "end_user_customer" },
        { name: "Per Learner - Learner Pays", key: "end_user" },
        { name: "Annual Support & Maintenance", key: "licence" },
      ],
      paymentType: "",
      invoiceFrequency: [
        { name: "Monthly", id: "monthly" },
        { name: "Quarterly", id: "quarterly" },
        { name: "Annually", id: "annually" },
      ],
      invoiceType: "",
      costPerLearner: "",
      currentType: [
        { name: "CAD", id: "cad" },
        { name: "USD", id: "usd" },
      ],
      currency: "",
      startDate: new Date(),
      endDate: new Date(),
      taxStatus: false,
      tax: "",
      averageCost: "",
      cost: "",
      numberOfLearners: "",
      reconciled: "",
      totalInstructor: "",
      ltiIntegrationStatus: false,
      ssdStatus: false,
      gradingStatus: false,
      singleWeightedGrade: false,
      ltiModule: {},
      fullToolbar: [],
      terms: "",
      instructorReqStatus: false,
      useDefault: false,
      removeModules: [],
      ltiList: [],
      plagiarism_strictness: 0,
      enable_plagiarism_detection: false,
      enable_course_plagiarism_detection: false,
      selectedELM: false,
      plagiarismLOs: {},
      selectedLO: "",
      showPlagiarismSettings: false,
      sequential_mod_access: false, 
      sequential_24_hour_delay: false,
      disableLearnerRetries: true,
      enableCertificateOfCompletion: false,
      customCertificateTitle: "", 
      enable_reporting: false,
      enable_grading_report: false,
      enable_completion_report: false,
      enable_assessment_report: false,
      learner_display_section: false, 
      auto_create_sections: false,
      selectedLearnMod: null,
      interaction_grading_weights: [],
      weightedGrades: [],
      enable_llm_retries: false,
      llm_retries_limit: 0,
    };
  },
  methods: {
    removeModule(i) {
      if (this.addedModules[i].id) {
        this.removeModules.push(this.addedModules[i]);
      }
      this.addedModules.splice(i, 1);
    },
    removeLti() {
      this.ltiModule = {};
      this.searchModule = "";
    },
    selectReconciled(type) {
      this.reconciled = type;
    },
    selectCurrencyType(type) {
      this.currency = type;
    },
    selectPaymentType(type) {
      this.paymentType = type;
    },
    selectInvoiceFrequency(type) {
      this.invoiceType = type;
    },
    setTab(currentTab) {
      // if (currentTab === 'plagiarism') {
      //   this.getPlagiarismSettings(42, 15)
      // }
      this.tab = currentTab;
    },
    selectedCustomerType(customer) {
      this.customerType = customer;
    },
    getCustomerType() {
      api
        .getCustomerTypes()
        .then((res) => {
          this.customerTypes = res.data;
        })
        .catch();
    },
    getCharacters() {
      const page = 1;
      if (this.searchCharacters.length === 0) {
        this.charactersList = [];
      } else {
        api
          .searchCustomers(page, this.searchCharacters)
          .then((res) => {
            this.charactersList = res.data.data;
            if (this.addedCharacters.length > 0) {
              this.addedCharacters.forEach((element) => {
                const index = this.charactersList.findIndex((elem) => {
                  return parseInt(elem.id) === parseInt(element.id);
                });
                if (index !== -1) {
                  this.charactersList[index].status = true;
                }
              });
            }
          })
          .catch();
      }
    },
    getModules() {
      const page = 1;
      if (this.searchModule.length === 0) {
        this.modulesList = [];
      } else {
        api
          .searchModules(page, this.searchModule)
          .then((res) => {
            this.modulesList = res.data.data;
            if (this.addedModules.length > 0) {
              this.addedModules.forEach((element) => {
                const index = this.modulesList.findIndex((elem) => {
                  return parseInt(elem.id) === parseInt(element.id);
                });
                if (index !== -1) {
                  this.modulesList[index].status = true;
                }
              });
            }
          })
          .catch();
      }
    },
    getLmsModules() {
      api
        .searchLmsModules()
        .then((res) => {
          this.ltiList = res.data.data;
        })
        .catch();
    },
    createCourses() {
      this.$refs.form.validate().then((success) => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          const fb = new FormData();
          fb.append("customer_course[name]", this.courseName);
          fb.append(
            "customer_course[customer_id]",
            this.addedCharacters.customer_id
          );
          if (this.addedModules.length > 0) {
            this.addedModules.forEach((list, index) => {
              if (!list.id) {
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][learn_mod_id]",
                  list.module_id
                );
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][elm_order]",
                  index
                );
              } else {
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][id]",
                  list.id
                );
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][learn_mod_id]",
                  list.module_id
                );
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][elm_order]",
                  index
                );
              }
            });
          }

          if (this.removeModules.length > 0) {
            this.removeModules.forEach((mod) => {
              if (mod.id) {
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][id]",
                  mod.id
                );
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][learn_mod_id]",
                  mod.module_id
                );
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][_destroy]",
                  true
                );
              }
            });
          }

          fb.append("customer_course[start_date]", this.startDate);
          fb.append("customer_course[end_date]", this.endDate);
          fb.append("customer_course[open_start]", true);
          fb.append("customer_course[currency]", this.currency.id);
          fb.append("customer_course[taxable]", this.taxStatus);
          fb.append("customer_course[tax_rate]", this.tax);
          fb.append("customer_course[no_of_learners]", this.numberOfLearners);
          fb.append("customer_course[reconciled]", this.reconciled.id);
          fb.append("customer_course[use_default_terms]", this.useDefault);
          fb.append("customer_course[terms_and_conditions]", this.terms);
          fb.append(
            "customer_course[instructor_required]",
            this.instructorReqStatus
          );
          fb.append(
            "customer_course[max_no_instructors]",
            this.totalInstructor
          );
          fb.append(
            "customer_course[average_cost_per_learner]",
            this.averageCost
          );

          fb.append("customer_course[price]", this.cost);
          fb.append("customer_course[no_charge]", this.noCharge);
          if (!this.noCharge) {
            fb.append("customer_course[cost_per_learner]", this.costPerLearner);
            fb.append(
              "customer_course[invoice_frequency]",
              this.invoiceType.id
            );
            fb.append("customer_course[payment_type]", this.paymentType.key);
          }

          fb.append(
            "customer_course[lti_setting_attributes][lti_integration]",
            this.ltiIntegrationStatus
          );
          fb.append("customer_course[sequential_mod_access]", this.sequential_mod_access);
          fb.append("customer_course[sequential_24_hour_delay]", this.sequential_24_hour_delay);
          fb.append("customer_course[disable_reattempts]", this.disableLearnerRetries);
          fb.append("customer_course[enable_certificate_of_completion]", this.enableCertificateOfCompletion);
          fb.append("customer_course[custom_certificate_title]", this.customCertificateTitle);
          fb.append("customer_course[enable_reporting]", this.enable_reporting);
          fb.append("customer_course[enable_grading_report]", this.enable_grading_report);
          fb.append("customer_course[enable_completion_report]", this.enable_completion_report);
          fb.append("customer_course[enable_assessment_report]", this.enable_assessment_report);
          fb.append("customer_course[learner_display_section]", this.learner_display_section);
          fb.append("customer_course[auto_create_sections]", this.auto_create_sections);
          fb.append("customer_course[enable_llm_retries]", this.enable_llm_retries);
          if (this.enable_llm_retries) {
            fb.append("customer_course[llm_retries_limit]", this.llm_retries_limit);
          } else {
            fb.append("customer_course[llm_retries_limit]", 0);
          }
          fb.append("customer_course[lti_setting_attributes][lti_integration]", this.ltiIntegrationStatus);
          if (this.ltiIntegrationStatus) {
            fb.append(
              "customer_course[lti_setting_attributes][learning_management_system_id]",
              this.ltiModule.id
            );
            fb.append(
              "customer_course[lti_setting_attributes][sso]",
              this.ssdStatus
            );
            fb.append(
              "customer_course[lti_setting_attributes][grading]",
              this.gradingStatus
            );
            if (!this.gradingStatus) {
              fb.append(
                "customer_course[lti_setting_attributes][single_weighted_grade]",
                false
              );
            } else {
              fb.append(
                "customer_course[lti_setting_attributes][single_weighted_grade]",
                this.singleWeightedGrade
              );
            }
          }

          // Append interaction_grading_weights_attributes
          if (this.selectedLearnMod && Array.isArray(this.selectedLearnMod.interactions)) {
            this.selectedLearnMod.interactions?.forEach((interaction, index) => {
              console.log(interaction, index, "interaction");
              fb.append(`customer_course[interaction_grading_weights_attributes][${index}][id]`, interaction.id);
              fb.append(`customer_course[interaction_grading_weights_attributes][${index}][learning_object_id]`, interaction.attributes.learning_object_id);
              fb.append(`customer_course[interaction_grading_weights_attributes][${index}][module_id]`, interaction.attributes.module_id);
              fb.append(`customer_course[interaction_grading_weights_attributes][${index}][customer_course_id]`, interaction.attributes.customer_course_id);
              fb.append(`customer_course[interaction_grading_weights_attributes][${index}][weight]`, interaction.attributes.weight);
              fb.append(`customer_course[interaction_grading_weights_attributes][${index}][interaction_name]`, interaction.attributes.interaction_name);
            });
          }
          if (this.customer_id) {
            fb.append("id", this.customer_id);
            api.editCourse(fb, this.customer_id).then(() => {
              utilFunctionService.showSuccess(
                commonConfig.appMessages.courseEdit
              );
              this.hide("createCourse");
            });
          } else {
            api.createCourse(fb).then(() => {
              utilFunctionService.showSuccess(
                commonConfig.appMessages.courseCreate
              );
              this.hide("createCourse");
            });
          }
        }
      });
    },
    saveCourse() {
      this.$refs.form.validate().then((success) => {
        this.isActive = true;
        if (!success) {
          return;
        } else {
          const fb = new FormData();
          fb.append("customer_course[name]", this.courseName);
          fb.append(
            "customer_course[customer_id]",
            this.addedCharacters.customer_id
          );
          if (this.addedModules.length > 0) {
            this.addedModules.forEach((list, index) => {
              if (!list.id) {
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][learn_mod_id]",
                  list.module_id
                );
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][elm_order]",
                  index
                );
              } else {
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][id]",
                  list.id
                );
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][learn_mod_id]",
                  list.module_id
                );
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][elm_order]",
                  index
                );
              }
            });
          }

          if (this.removeModules.length > 0) {
            this.removeModules.forEach((mod) => {
              if (mod.id) {
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][id]",
                  mod.id
                );
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][learn_mod_id]",
                  mod.module_id
                );
                fb.append(
                  "customer_course[customer_learn_mods_attributes][][_destroy]",
                  true
                );
              }
            });
          }

          fb.append("customer_course[start_date]", this.startDate);
          fb.append("customer_course[end_date]", this.endDate);
          fb.append("customer_course[open_start]", true);
          fb.append("customer_course[currency]", this.currency.id);
          fb.append("customer_course[taxable]", this.taxStatus);
          fb.append("customer_course[tax_rate]", this.tax);
          fb.append("customer_course[no_of_learners]", this.numberOfLearners);
          fb.append("customer_course[reconciled]", this.reconciled.id);
          fb.append("customer_course[use_default_terms]", this.useDefault);
          fb.append("customer_course[terms_and_conditions]", this.terms);
          fb.append(
            "customer_course[instructor_required]",
            this.instructorReqStatus
          );
          fb.append(
            "customer_course[max_no_instructors]",
            this.totalInstructor
          );
          fb.append(
            "customer_course[average_cost_per_learner]",
            this.averageCost
          );

          fb.append("customer_course[price]", this.cost);
          fb.append("customer_course[no_charge]", this.noCharge);
          if (!this.noCharge) {
            fb.append("customer_course[cost_per_learner]", this.costPerLearner);
            fb.append(
              "customer_course[invoice_frequency]",
              this.invoiceType.id
            );
            fb.append("customer_course[payment_type]", this.paymentType.key);
          }

          fb.append(
            "customer_course[lti_setting_attributes][lti_integration]",
            this.ltiIntegrationStatus
          );
          fb.append("customer_course[sequential_mod_access]", this.sequential_mod_access);
          fb.append("customer_course[sequential_24_hour_delay]", this.sequential_24_hour_delay);
          fb.append("customer_course[disable_reattempts]", this.disableLearnerRetries);
          fb.append("customer_course[enable_certificate_of_completion]", this.enableCertificateOfCompletion);
          fb.append("customer_course[custom_certificate_title]", this.customCertificateTitle);
          fb.append("customer_course[enable_reporting]", this.enable_reporting);
          fb.append("customer_course[enable_grading_report]", this.enable_grading_report);
          fb.append("customer_course[enable_completion_report]", this.enable_completion_report);
          fb.append("customer_course[enable_assessment_report]", this.enable_assessment_report);
          fb.append("customer_course[learner_display_section]", this.learner_display_section);
          fb.append("customer_course[auto_create_sections]", this.auto_create_sections);

          fb.append("customer_course[lti_setting_attributes][lti_integration]", this.ltiIntegrationStatus);
          if (this.ltiIntegrationStatus) {
            fb.append(
              "customer_course[lti_setting_attributes][learning_management_system_id]",
              this.ltiModule.id
            );
            fb.append(
              "customer_course[lti_setting_attributes][sso]",
              this.ssdStatus
            );
            fb.append(
              "customer_course[lti_setting_attributes][grading]",
              this.gradingStatus
            );
            if (!this.gradingStatus) {
              fb.append(
                "customer_course[lti_setting_attributes][single_weighted_grade]",
                false
              );
            } else {
              fb.append(
                "customer_course[lti_setting_attributes][single_weighted_grade]",
                this.singleWeightedGrade
              );
            }
            // Append interaction_grading_weights_attributes
            if (this.selectedLearnMod && Array.isArray(this.selectedLearnMod.interactions)) {
              this.selectedLearnMod.interactions?.forEach((interaction, index) => {
                console.log(interaction, index, "interaction");
                fb.append(`customer_course[interaction_grading_weights_attributes][${index}][id]`, interaction.id);
                fb.append(`customer_course[interaction_grading_weights_attributes][${index}][learning_object_id]`, interaction.attributes.learning_object_id);
                fb.append(`customer_course[interaction_grading_weights_attributes][${index}][module_id]`, interaction.attributes.module_id);
                fb.append(`customer_course[interaction_grading_weights_attributes][${index}][customer_course_id]`, interaction.attributes.customer_course_id);
                fb.append(`customer_course[interaction_grading_weights_attributes][${index}][weight]`, interaction.attributes.weight);
                fb.append(`customer_course[interaction_grading_weights_attributes][${index}][interaction_name]`, interaction.attributes.interaction_name);
              });
            }
          }
          if (this.customer_id) {
            fb.append("id", this.customer_id);
            api.editCourse(fb, this.customer_id).then(() => {
              utilFunctionService.showSuccess(
                commonConfig.appMessages.courseEdit
              );
            });
          } else {
            api.createCourse(fb).then(() => {
              utilFunctionService.showSuccess(
                commonConfig.appMessages.courseCreate
              );
            });
          }
        }
      });
    },
    clearForm() {
      this.name = "";
      this.characters = [];
      (this.tab = "address"), (this.email = ""), (this.contactList = []);
      this.removeModules = [];
      this.addedCharacters = {};
      this.addedModules = [];
      this.addedCharacters = {};
      this.courseName = "";
      this.numberOfLearners = "";
      this.totalInstructor = "";
      this.cost = "";
      this.averageCost = "";
      this.tax = "";
      this.weightedGrades = [];
      this.interaction_grading_weights = [];
    },
    hide() {
      this.clearForm();
      this.$parent.hide("createCourse");
    },
    addCharacters(e) {
      this.charactersList = [];
      this.addedCharacters = { name: e.attributes.name, customer_id: e.id };
      this.searchCharacters = "";
    },
    removeCharcter() {
      this.addedCharacters = {};
    },
    addModules(e) {
      this.modulesList = [];
      this.addedModules.push({
        moduleName: e.attributes.name,
        module_id: e.id,
        worldName: e.attributes.world_name,
        photoUrl:
          e.attributes.photo_url != false
            ? e.attributes.photo_url.thumbnail
            : false,
      });
      this.searchModule = "";
    },
    addLtiModule(module) {
      this.ltiModule = module;
      this.searchModule = "";
      this.modulesList = [];
    },
    beforeOpen(event) {
      this.clearForm();
      this.getCustomerType();
      this.getLmsModules();
      this.customer_id = event.params.customer_id;
      this.tab = event.params.tab;
      if (this.customer_id) this.getCourseDetails();
    },
    getPlagiarismSettings(course_id, lo_id) {
      api.
        getPlagiarismLo(course_id, lo_id)
        .then((res) => {
          this.plagiarism_strictness = res.data.plagiarism_strictness;
          this.enable_plagiarism_detection = res.data.enable_plagiarism_detection;
        })
    },
    getCourseDetails() {
      api
        .getCourseDetails(this.customer_id)
        .then((res) => {
          //console.log(res.data.data, "*** res.data.data in getCourseDetails");
          this.tab = "address";
          this.courseName = res.data.data.attributes.name;
          this.addedCharacters = {
            name: res.data.data.attributes.customer_name,
            customer_id: res.data.data.attributes.customer_id,
          };
          this.instructorReqStatus =
            res.data.data.attributes.instructor_required;
          this.totalInstructor = res.data.data.attributes.max_no_instructors;
          this.noCharge = res.data.data.attributes.no_charge;
          this.useDefault = res.data.data.attributes.use_default_terms;
          this.terms = res.data.data.attributes.terms_and_conditions;

          if (res.data.data.attributes.lti_setting !== null) {
            this.ltiIntegrationStatus = res.data.data.attributes.lti_setting.attributes.lti_integration;
            this.ssdStatus = res.data.data.attributes.lti_setting.attributes.sso;
            this.gradingStatus = res.data.data.attributes.lti_setting.attributes.grading;
            this.singleWeightedGrade = res.data.data.attributes.lti_setting.attributes.single_weighted_grade;
          } else {
            this.ltiIntegrationStatus = "";
            this.ssdStatus = "";
            this.gradingStatus = "";
            this.singleWeightedGrade = "";
          }

          this.costPerLearner = res.data.data.attributes.cost_per_learner;
          this.sequential_mod_access = res.data.data.attributes.sequential_mod_access;
          this.sequential_24_hour_delay = res.data.data.attributes.sequential_24_hour_delay;
          this.disableLearnerRetries = res.data.data.attributes.disable_reattempts;
          this.enableCertificateOfCompletion = res.data.data.attributes.enable_certificate_of_completion;
          this.customCertificateTitle = res.data.data.attributes.custom_certificate_title;
          this.averageCost = res.data.data.attributes.average_cost_per_learner;
          this.tax = res.data.data.attributes.tax_rate;
          this.taxStatus = res.data.data.attributes.taxable;
          this.startDate = new Date(res.data.data.attributes.start_date);
          this.endDate = new Date(res.data.data.attributes.end_date);
          this.enable_course_plagiarism_detection = res.data.data.attributes.enable_plagiarism_detection;
          this.enable_reporting = res.data.data.attributes.enable_reporting;
          this.enable_grading_report = res.data.data.attributes.enable_grading_report;
          this.enable_completion_report = res.data.data.attributes.enable_completion_report;
          this.enable_assessment_report = res.data.data.attributes.enable_assessment_report;
          this.learner_display_section = res.data.data.attributes.learner_display_section;
          this.auto_create_sections = res.data.data.attributes.auto_create_sections;
          this.interaction_grading_weights = res.data.data.attributes.interaction_grading_weights;
          this.enable_llm_retries = res.data.data.attributes.enable_llm_retries;
          this.llm_retries_limit = res.data.data.attributes.llm_retries_limit;

          const learnMods = res.data.data.attributes.customer_learn_mods;

          if (learnMods.length > 0) {
            learnMods.map((e) => {
              this.addedModules.push({
                id: e.id,
                moduleName: e.attributes.learn_mod.data.attributes.name,
                module_id: e.attributes.learn_mod_id,
                worldName: e.attributes.learn_mod.data.attributes.world_name,
                photoUrl:
                  e.attributes.learn_mod.data.attributes.photo_url != false
                    ? e.attributes.learn_mod.data.attributes.photo_url.thumbnail
                    : false,
              });
            });
          }
          console.log(this.interaction_grading_weights, "interaction_grading_weights");
          if (this.interaction_grading_weights.length > 0) {
            this.weightedGrades = [];
            learnMods.map((e) => {
              this.weightedGrades.push({
                name: e.attributes.learn_mod.data.attributes.name,
                interactions: this.interaction_grading_weights.filter((item) => item.attributes.module_id === e.attributes.learn_mod_id),
                id: e.attributes.learn_mod_id,
              });
            });
            if (this.weightedGrades.length > 0) {
              this.selectedLearnMod = this.weightedGrades[0];
            }
          }

          console.log(this.weightedGrades, "weightedGrades");

          this.numberOfLearners = res.data.data.attributes.no_of_learners;
          this.cost = res.data.data.attributes.price || "";
          const invoiceFreq = res.data.data.attributes.invoice_frequency;
          this.invoiceType =
            invoiceFreq == "quarterly"
              ? { name: "Quarterly", id: "quarterly" }
              : invoiceFreq == "annually"
              ? { name: "Annually", id: "annually" }
              : invoiceFreq == "monthly"
              ? { name: "Monthly", id: "monthly" }
              : "";
          const reconcile = res.data.data.attributes.reconciled;
          this.reconciled =
            reconcile == "quarterly"
              ? { name: "Quarterly", id: "quarterly" }
              : reconcile == "annually"
              ? { name: "Annually", id: "annually" }
              : reconcile == "monthly"
              ? { name: "Monthly", id: "monthly" }
              : "";
          const currency = res.data.data.attributes.currency;
          this.currency =
            currency == "usd"
              ? { name: "USD", id: "usd" }
              : { name: "CAD", id: "cad" };
          const paymentType = res.data.data.attributes.payment_type;
          this.paymentType =
            paymentType == "end_user_customer"
              ? {
                  name: "Per Learner - Customer Pays",
                  key: "end_user_customer",
                }
              : paymentType == "end_user"
              ? { name: "Per Learner - Learner Pays", key: "end_user" }
              : paymentType == "licence"
              ? { name: "Annual Support & Maintenance", key: "licence" }
              : "";

          if (this.ltiIntegrationStatus) {
            this.ltiModule = {
              attributes: {
                name: res.data.data.attributes.lti_setting.attributes
                  .learning_management_system.data.attributes.name,
              },
              id: res.data.data.attributes.lti_setting.attributes
                .learning_management_system_id,
            };
          }
        })
        .catch();
    },
    getElmLOs(modID) {
      api.
        getAllILOs(this.customer_id, modID)
        .then((res => {
          if (res.data.length > 0) {
            this.showPlagiarismSettings = true;
            this.selectedELM = modID;
            this.plagiarismLOs = res.data;
            this.selectedLO = this.plagiarismLOs[0].id;
            this.getPlagiarismSettings(this.selectedELM, this.plagiarismLOs[0].id);
          } else {
            utilFunctionService.showErr("There are no Interactive Learning Objects associated with this module");
          }
        }))
    },
    switchActiveLO(loID) {
      this.selectedLO = loID;
      this.getPlagiarismSettings(this.selectedELM,loID)
    },
    savePlagiarismSetting() {

      const fb = new FormData();
      fb.append("customer_course[enable_plagiarism_detection]", this.enable_course_plagiarism_detection);

      api.editCourse(fb, this.customer_id).then(() => {
          
          if (this.enable_course_plagiarism_detection == true) {
            this.plagiarismLOs.forEach(element => {

              const data = {
                enable_plagiarism_detection: element.enable_plagiarism_detection, 
                plagiarism_strictness: element.plagiarism_strictness
              }

              api.
                updatePlagiarismLo(this.selectedELM, element.id, data)
                .then(() => {
                  utilFunctionService.showSuccess("Plagiarism Settings saved.");
                })
                .catch(() => {
                  utilFunctionService.showErr("An error occurred. Please reach out to Ametros technical support.");
                })
            })
          } else {
            utilFunctionService.showSuccess("Plagiarism Settings saved.");
            
          }
      });

      
    
    }
  },
};
</script>

<style lang="scss" scoped>

  .form-checkboxes {
    margin-bottom: 16px;
  }
  .form-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .plagiarism-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .enable-course-plagiarism {
    margin-bottom: 2em;
    label {
      margin-left: 2em;
    }
  }

  .elm-view {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
    overflow-y: scroll;
    width: 100%;
    .elm-btn {
      border: #ccc 2px solid;
      background-color: #ffffff;
      color: #000000;
      margin-right: 16px;
      &:hover {
        background-color: #ebebeb;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .reporting-indent {
    padding-left: 2em;
  }

  .lo-view {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    .lo-view__selection {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      overflow: auto;
      max-height: 320px;
      width: 300px;
      overflow-x: hidden;
      padding: 0 0.5em;
      .lo-view__btn {
        border: #ccc 2px solid;
        background-color: #ffffff;
        color: #000000;
        margin-bottom: 16px;
        height: 100%;
        border-radius: 5px;
        min-width: 150px;
        &:hover {
          background-color: #ebebeb;
        }
      }
    }
    .lo-view__settings {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: 16px;
      width: 100%;
      .lo-settings__checkbox {
        margin-bottom: 16px;
        input {
          margin-right: 16px;
        }
      }
      .lo-settings__range {
        width: 100%;
        display: flex;
        input[type="range"] {
          margin: 0 16px 32px 16px;
          width: 68%;
        }
        label {
          width: 70px;
        }
      }
      .lo-view__btn {
        border: #ccc 1px solid;
        background-color: #3d78bc;
        color: #fff;
        margin-bottom: 16px;
        align-self: flex-end;
        &:hover {
          background-color: #2962a3;
        }
      }
    }
  }
  .interaction-container {
    padding: 16px 0;
    .interaction-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 16px;
    }
  }
</style>
