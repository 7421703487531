import { render, staticRenderFns } from "./SubmissionCard.vue?vue&type=template&id=d28e7958&scoped=true"
import script from "./SubmissionCard.vue?vue&type=script&lang=js"
export * from "./SubmissionCard.vue?vue&type=script&lang=js"
import style0 from "./SubmissionCard.vue?vue&type=style&index=0&id=d28e7958&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d28e7958",
  null
  
)

export default component.exports